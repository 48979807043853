<template>
  <div class="gooog_comment">
    <mescroll :up="mescrollUp" :down="mescrollDown">
      <div slot="scrollList">
        <div class="gooog_comment_name">
          <span>商品评价</span>
          <span class="gooog_comment_nub"> ({{ dataList.comment_total }}条)</span>
          <span class="gooog_comment_r">好评率{{ dataList.good_favourable }}</span>
        </div>
        <div class="gooog_comment_list">
          <div v-for="(item,index) in list" :key="index" class="gooog_comment_box">
            <div class="gooog_comment_box_son">
              <div class="gooog_comment_l">
                <img :src="item.litpic" alt="">
              </div>
              <div class="gooog_comment_l">
                <h4>{{ item.nickname }}</h4>
                <h5 v-for="(good,gIndex) in item.goods_score" :key="gIndex">
                  <img class="gooog_comment_image" src="../../assets/images/star.png" alt="">
                </h5>
              </div>
            </div>
            <h3>{{ item.comment }}</h3>
            <h6>{{ item.goods_spec }}</h6>
          </div>
        </div>
      </div>
    </mescroll>
  </div>
</template>

<script>
import { productCommentList } from '@/services/api'
import mescroll from '@/component/fresh_scroll/mescroll'

export default {
  name: 'GoogCommentList',
  components: {
    mescroll
  },
  data() {
    return {
      list: '',
      dataList: '',
      mescrollDown: {
        use: true,
        // top:'50px',
        bottom: '0'
      },
      mescrollUp: {
        initCallBack: this.mescrollInit,
        callback: this.upCallback, // 上拉回调,此处可简写; 相当于 callback: function (page, mescroll) { getListData(page); }
        page: {
          num: 0, // 当前页码,默认0,回调之前会加1,即callback(page)会从1开始
          size: 10 // 每页数据的数量
        },
        noMoreSize: 5, // 如果列表已无数据,可设置列表的总数量要大于等于5条才显示无更多数据;避免列表数据过少(比如只有一条数据),显示无更多数据会不好看
        lazyLoad: {
          use: true // 是否开启懒加载,默认false
        }
      },
      page: 1,
      pageSize: 10
    }
  },
  mounted() {
    this.dataAll()
  },
  methods: {
    dataAll: function() {
      const data = {
        product_id: this.$route.query.goodId,
        page: this.page,
        pageSize: this.pageSize
      }
      productCommentList(data).then((res) => {
        if (Number(res.code) === 200) {
          this.list = res.data.result
          this.dataList = res.data
        }
      })
    },
    mescrollInit(data) {
      this.mescroll = data
    },
    // 上拉回调 page = {num:1, size:10}; num:当前页 ,默认从1开始; size:每页数据条数,默认10
    upCallback(page, mescroll) {
      // 接口请求数据
      this.getListDataFromNet(
        this.pdType,
        page.num,
        page.size,
        (arr) => {
          // 如果是第一页需手动制空列表
          if (page.num === 1) this.list = []
          // 把请求到的数据添加到列表
          this.list = this.list.concat(arr)

          // 数据渲染成功后,隐藏下拉刷新的状态
          this.$nextTick(() => {
            mescroll.endSuccess(arr.length)
          })
        },
        () => {
          // 联网异常,隐藏上拉和下拉的加载进度
          mescroll.endErr()
        }
      )
    },
    getListDataFromNet: function(pdType, pageNum, pageSize, successCallback, errorCallback) {
      const newParams = {
        product_id: this.$route.query.goodId,
        page: pageNum,
        pageSize: pageSize
      }
      productCommentList(newParams).then(res => {
        if (Number(res.code) === 200) {
          successCallback(res.data.result)
        }
      })
    }

  }

}
</script>

<style scoped lang="less">
.gooog_comment {
  background: #fff;

  .gooog_comment_name {
    overflow: hidden;
    padding: 12px;
    text-align: left;

    .gooog_comment_nub {
      color: #999;
      font-size: 12px;
    }

    .gooog_comment_r {
      float: right;
      font-size: 12px;

      span {
        color: #666;
      }
    }
  }

  .gooog_comment_list {
    background: #fff;

    .gooog_comment_box {
      border-bottom: 1px solid rgba(0, 0, 0, .05);
      text-align: left;
      padding: 12px;
      box-sizing: border-box;

      .gooog_comment_box_son {
        overflow: hidden;

        img {
          width: 32px;
          height: 32px;
          border-radius: 50%;
        }

        .gooog_comment_l {
          float: left;
          margin-right: 4px;

          h4 {
            font-size: 12px;
            font-weight: 500;
          }

          h5 {
            display: inline-block;
          }

          .gooog_comment_image {
            width: 12px;
            height: 12px;
          }
        }
      }

      h3 {
        margin-top: 12px;
        font-size: 12px;
      }

      h6 {
        margin-top: 10px;
        font-size: 12px;
        color: #999;
      }
    }

  }
}

</style>
